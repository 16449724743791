import './home.scss';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-home'))
    return;

  console.info('page: home');
});
