import './about.scss';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-about'))
    return;

  dompack.qS('html').classList.add('show');
});
