// import { setupDefaultWebwerfSite } from '@mod-webwerf_sites/js/site';

import '@mod-system/js/wh/integration';
import 'dompack/browserfix/reset.css';

import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';

export function setupDefaultWebwerfSite({
  _openLinksInNewWindow = true,
} = {}) {
  if (_openLinksInNewWindow)
    openLinksInNewWindow();
}
