import { setupDefaultWebwerfSite } from '@mod-webwerf_sites/js/site';

import './shared/forms/forms';
import './shared/rtd/rtd';

import './alsikindehemelcom.scss';

import './widgets/widgets';

import './pages/home/home';
import './pages/about/about';

setupDefaultWebwerfSite({ /*openLinksInNewWindow: true*/ });
